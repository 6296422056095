import bg from './bg.svg'
import logo from './logo.svg'
import ios from './ios.png'
import android from './android.png'
import './App.css'
import { SocialIcon } from 'react-social-icons'

function App() {
	const link = {
		display: 'inline-block',
		marginTop: 32,
		maxWidth: 200
	}

	const image = {
		width: '100%'
	}

  return (
    <div style={{
			// backgroundColor: '#1c1f1f',
			background: `url(${bg})`,
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			width: '100%',
			height: '100%',
			minHeight: '100%'
		}}>
			<link href='https://fonts.googleapis.com/css?family=Grand Hotel' rel='stylesheet' />
			<link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet' />
			<div style={{
				margin: '0 auto',
				display: 'block',
				position: 'relative',
				top: '50%',
				transform: 'translateY(-50%)'
			}}>
				<img src={logo} style={{
					margin: '0 auto',
					display: 'block'
				}} />
				
				<div style={{
					fontFamily: 'Grand Hotel',
					color: '#03E8FC',
					fontSize: '48pt',
					textAlign: 'center',
					textShadow: '1px 1px 2px #03E8FC, 0 0 1em #03E8FC'
				}}>Connect Corner</div>

				<div style={{
					fontFamily: 'Inter',
					color: 'white',
					fontSize: '18pt',
					fontWeight: 'bold',
					textAlign: 'center',
					textShadow: '1px 1px 2px #aaa, 0 0 1em #aaa'
				}}>Download for iOS & Android!</div>

				<div style={{
					margin: '0 auto',
					display: 'flex',
					justifyContent: 'center',
					maxWidth: '90%',
					gap: 20
				}}>
					<a href="https://bit.ly/connect-corner-ios" target="_blank" style={link}><img src={ios} style={image} /></a>
					<a href="https://bit.ly/connect-corner-android" target="_blank" style={link}><img src={android} style={image} /></a>
				</div>


				<div style={{
					margin: '0 auto',
					display: 'flex',
					justifyContent: 'center',
					maxWidth: '90%',
					gap: 10,
					marginTop: 20
				}}>
					<SocialIcon url="https://www.facebook.com/connectcornerapp/" target="_blank" style={{ width: 30, height: 30 }} />
					<SocialIcon url="https://www.instagram.com/connectcornerapp/" target="_blank" style={{ width: 30, height: 30 }} />
				</div>

				<div style={{
					fontFamily: 'Inter',
					color: 'white',
					fontSize: '14pt',
					textAlign: 'center',
					marginTop: 32,
					display: 'none'
				}}>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa<br/>ФФФФФФФФФФФФФФФФФФФФФФФфф</div>
			</div>
    </div>
  )
}

export default App
